/* src/BouncingDots.css */

@keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1.0);
    }
  }
  
  .bouncing-dots .dot {
    animation: bounce 1.4s infinite ease-in-out both;
    background-color: #B541FF;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin: 0 2px;
    width: 10px;
  }
  
  .bouncing-dots .dot:nth-child(1) { animation-delay: -0.32s; }
  .bouncing-dots .dot:nth-child(2) { animation-delay: -0.16s; }
  .bouncing-dots .dot:nth-child(3) { animation-delay: 0s; }
